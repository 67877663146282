import { useTranslation } from 'next-i18next'

const ShowMoreButton = ({ isShowMore, onClick }: { isShowMore: boolean; onClick: () => void }) => {
  const { t } = useTranslation('common')

  return (
    <div data-testid='show-more-btn' className='absolute bottom-0 right-0 flex items-center'>
      <span className='via-grayscale-000 to-grayscale-000 min-h-[22px] w-[50px] bg-gradient-to-r from-transparent' />
      <button
        type='button'
        onClick={onClick}
        className='bg-grayscale-000 text-grayscale-400 cursor-pointer border-none text-end text-sm'
      >
        ...{isShowMore ? t('common.collapse') : t('common.check_detail')}
      </button>
    </div>
  )
}

export { ShowMoreButton }
