import Image from 'next/image'
import RatingStars from './rating-stars'

function UserInfoBar({
  rating,
  userName,
  createdAt,
  imgUrl,
}: {
  rating: number
  userName: string
  createdAt: string
  imgUrl: string
}) {
  return (
    <div className='flex items-center justify-between gap-2 text-sm'>
      <div className='flex items-center justify-start gap-x-3'>
        <Image height={38} width={38} src={imgUrl} alt='Random User Image' />
        <div>
          <div className='text-grayscale-800 text-sm'>{userName}</div>

          <div className='text-grayscale-400 text-sm'>{createdAt}</div>
        </div>
      </div>
      <RatingStars rating={rating} showText={false} isDisplayedOnlyOneStarIcon={false} />
    </div>
  )
}

export default UserInfoBar
