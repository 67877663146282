import type { ProductTypeInHomeProductsSection } from './types'
import { isBefore, parseISO } from 'date-fns'
import { useMemo } from 'react'
import SwiperWithTitleAndControls from '@libs-components/components/horizontal-swiper/variants/swiper-with-title-and-controls'
import ProductCard from '@/components/backend-controlled-components/product-card'

type Props = {
  title: string
  products: ProductTypeInHomeProductsSection[] | null | undefined
  filterSalePriceEndedProducts?: boolean
}

const ProductsSection = ({ title, products, filterSalePriceEndedProducts }: Props) => {
  const processedProducts = useMemo(() => {
    if (!products) return []

    if (!filterSalePriceEndedProducts) return products

    return products.filter(product => {
      return (
        product.attributes.salePriceEndsAt &&
        isBefore(new Date(), parseISO(product.attributes.salePriceEndsAt))
      )
    })
  }, [products, filterSalePriceEndedProducts])

  if (!processedProducts?.length) return null

  return (
    <section className='mobileUp:px-0 mx-auto max-w-[912px] px-4 pt-[30px]'>
      <SwiperWithTitleAndControls
        tag='ul'
        title={title}
        slideClassName='!w-[258px] mr-4 last:mr-0'
        pagination={false}
        spaceBetween={16}
        cssMode
      >
        {processedProducts.map(product => (
          <ProductCard key={product.id} cardType='vertical' product={product} />
        ))}
      </SwiperWithTitleAndControls>
    </section>
  )
}

export default ProductsSection
