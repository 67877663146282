import type { ProductType } from '../../../../types/product'
import dynamic from 'next/dynamic'
import { useMemo, useCallback } from 'react'
import VerticalCardSkeleton from './vertical/skeleton'
import HorizontalCardSkeleton from './horizontal/skeleton'
import RecommendCardSkeleton from './Recommend/skeleton'

const Component = {
  vertical: dynamic(import('./vertical'), {
    loading: () => <VerticalCardSkeleton />,
  }),
  horizontal: dynamic(import('./horizontal'), {
    loading: () => <HorizontalCardSkeleton />,
  }),
  recommend: dynamic(import('./Recommend'), {
    loading: () => <RecommendCardSkeleton />,
  }),
} as const

export type ProductCardProps = {
  imagePriority?: boolean
  cardType: keyof typeof Component
  product: ProductType.Data
  as?: 'div' | 'li'
  className?: string
  onClick?: (prodcut: ProductType.Data) => void
}

const ProductCard = ({
  imagePriority,
  cardType,
  product,
  as: DomTag = 'div',
  className,
  onClick,
}: ProductCardProps) => {
  const Card = useMemo(() => Component[cardType], [cardType])
  const _onClick = useCallback(() => {
    onClick?.(product)
  }, [product, onClick])

  if (!Card) return null

  return (
    <DomTag className={className} onClick={_onClick}>
      <Card imagePriority={imagePriority} product={product} />
    </DomTag>
  )
}

export default ProductCard
