import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import { Icon } from '@libs-components/components/icon'
import RecommendationFiltersPicker from '@/components/Recommendation/FiltersPicker'
import useInit from './useInit'

const RecommendationsSection = () => {
  const { t } = useTranslation()
  const {
    store: { hintFadeInUp },
    action: { onSubmit },
  } = useInit()

  return (
    <section className={hintFadeInUp ? 'mb-14' : ''}>
      <RecommendationFiltersPicker onSubmit={onSubmit}>
        {({ onOpen }) =>
          hintFadeInUp && (
            <div
              role='button'
              aria-describedby='recommendations entry'
              className={twMerge(
                'bg-grayscale-000 fixed bottom-0 left-0 right-0 z-[99]',
                hintFadeInUp,
              )}
              onClick={onOpen}
            >
              <div className='from-peach-300 to-peach-500 mobileUp:px-0 flex h-14 w-full cursor-pointer items-center justify-center gap-1 bg-gradient-to-tl px-4 text-white ease-in-out'>
                <Icon type='auto_awesome' />
                <span>{t('crowdin:recommendations-assistance.create-hint')}</span>
              </div>
            </div>
          )
        }
      </RecommendationFiltersPicker>
    </section>
  )
}

export default RecommendationsSection
