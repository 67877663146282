import { ENV } from './constants'

export const STAGE = process.env.NEXT_PUBLIC_STAGE

export const isProd = STAGE === ENV.PROD

export const isStaging = STAGE === ENV.STAGING

export const isDev = STAGE === ENV.DEV

let _isClient: boolean | null = null
export const isInClient = () => {
  return _isClient === null
    ? (_isClient = typeof window !== 'undefined')
    : _isClient
}
