import { twMerge } from 'tailwind-merge'
import { Icon, IconMapType } from '@libs-components/components/icon'

type Props = {
  className?: string
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  suffixIcon?: IconMapType
  onSuffixIconClick?: () => void
}

const Input = ({
  className = '',
  placeholder = '',
  onChange,
  suffixIcon,
  onSuffixIconClick,
}: Props) => {
  const containerClassName = twMerge(
    'flex items-center rounded-[33px] border border-solid border-grayscale-100 px-[12px] py-[7.5px]',
    className,
  )
  const inputClassName =
    'flex-1 border-none p-0 text-[14px] leading-[24.5px] bg-grayscale-000 tracking-[0.04px] text-grayscale-800 outline-none placeholder:text-grayscale-400 mobileUp:max-w-[250px]'
  return (
    <div className={containerClassName}>
      <input className={inputClassName} onChange={onChange} placeholder={placeholder}></input>
      {suffixIcon && (
        <button
          className='hover:text-grayscale-400 cursor-pointer border-none bg-transparent p-0 text-gray-300'
          onClick={onSuffixIconClick}
        >
          <Icon type={suffixIcon} />
        </button>
      )}
    </div>
  )
}

export default Input
