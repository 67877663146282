import { format, isWithinInterval, parseISO } from 'date-fns'
import { isProd } from '../env'

export const getCurrentTime = () => Date.now()

export function isInBetween(target: number | Date, { start, end, stagingStart, stagingEnd }: any) {
  return isWithinInterval(target, {
    start: new Date(!isProd && stagingStart ? stagingStart : start),
    end: new Date(!isProd && stagingEnd ? stagingEnd : end),
  })
}

export function iso8601StrToFormat(isoStr: string, pattern = 'yyyy-MM-dd') {
  return format(parseISO(isoStr), pattern)
}
