const VerticalCardSkeleton = () => {
  return (
    <div className='flex w-[258px] flex-col gap-2'>
      <div className='bg-grayscale-100 h-[143px] animate-pulse rounded-lg' />
      <div className='flex items-center gap-2'>
        <div className='bg-grayscale-100 h-[24px] w-[66px] animate-pulse rounded-full' />
        <div className='bg-grayscale-100 h-[24px] w-[66px] animate-pulse rounded-full' />
        <div className='bg-grayscale-100 h-[24px] w-[66px] animate-pulse rounded-full' />
      </div>
      <div className='bg-grayscale-100 h-[52px] animate-pulse' />
      <div className='bg-grayscale-100 h-[33px] animate-pulse' />
      <div className='bg-grayscale-100 h-[46px] animate-pulse' />
      <div className='bg-grayscale-100 h-[33px] animate-pulse' />
    </div>
  )
}

export default VerticalCardSkeleton
