import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { Icon } from '../../icon'

interface ArrowButtonProps {
  direction: 'left' | 'right'
  onClick: () => void
  className?: string
}

const ArrowButton = ({ direction, onClick, className }: ArrowButtonProps) => {
  const directionIcon = useMemo(
    () => (
      <Icon
        type={direction === 'left' ? 'arrow_back' : 'arrow_forward'}
        className='z-10 flex items-center justify-center rounded-full bg-white'
        size={28}
      />
    ),
    [direction],
  )

  return (
    <button
      onClick={onClick}
      className={twMerge(
        'text-grayscale-800 hover:text-grayscale-500 z-50 cursor-pointer border-none bg-transparent p-0 text-2xl outline-none transition-colors duration-150',
        className,
      )}
    >
      {directionIcon}
    </button>
  )
}

export default ArrowButton
