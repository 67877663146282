import type { SwiperProps } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import { useCallback, useEffect, useRef, useState, type ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { Text } from '../../../text'
import { Icon } from '../../../icon'
import { Button } from '../../../button/shop-button'
import isHTML from '../../../../utils/isHtml'
import HorizontalSwiperWithSideArrows from '../..'

type Props = Omit<
  ComponentProps<typeof HorizontalSwiperWithSideArrows>,
  'ref' | 'showArrows' | 'absoluteArrows' | 'pagination' | 'title'
> & {
  title: string
  description?: string
  pagination?: boolean
  titleRowClassName?: string
  handleArrowClick?: ({ type }: { type: 'previous' | 'next' }) => void
  activeIndex?: number
  showSkeleton?: boolean
}

const SwiperWithTitleAndControls = (props: Props) => {
  const {
    title,
    description,
    slideClassName,
    pagination,
    titleRowClassName,
    modules,
    handleArrowClick,
    className,
    activeIndex,
    showSkeleton,
    ...restProps
  } = props
  const swiperRef = useRef<SwiperCore>()
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(true)
  const isScrollable = !(isBeginning && isEnd)

  const slidePrev = useCallback(() => {
    if (swiperRef.current) swiperRef.current.slidePrev()
  }, [])
  const slideNext = useCallback(() => {
    if (swiperRef.current) swiperRef.current.slideNext()
  }, [])
  const onToEdge: NonNullable<SwiperProps['onToEdge']> = useCallback(swiper => {
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }, [])
  const onFromEdge: NonNullable<SwiperProps['onFromEdge']> = useCallback(swiper => {
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }, [])
  const onAfterInit: NonNullable<SwiperProps['onAfterInit']> = useCallback(swiper => {
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }, [])

  useEffect(() => {
    if (typeof activeIndex !== 'number' || !Number.isFinite(activeIndex) || !swiperRef.current)
      return

    swiperRef.current[restProps.loop ? 'slideToLoop' : 'slideTo'](activeIndex)
    swiperRef.current.update()
  }, [activeIndex, restProps.loop])

  return (
    <div className='flex w-full flex-col gap-3'>
      <div className='flex flex-col gap-1'>
        <div className={twMerge('flex w-full justify-between', titleRowClassName)}>
          <Text
            component='h3'
            variant='heading_m_500'
            className={twMerge(
              !title && showSkeleton && 'bg-grayscale-200 h-8 w-1/4 animate-pulse',
            )}
          >
            {title}
          </Text>
          {isScrollable && (
            <div className='flex items-center justify-center gap-2'>
              <Button
                aria-label='to previous slide button'
                onClick={slidePrev}
                className='bg-grayscale-000 text-grayscale-800 disabled:text-grayscale-300 [&:not(:disabled)]:hover:bg-grayscale-100 flex h-7 w-7 items-center justify-center rounded-full disabled:cursor-not-allowed'
                disabled={isBeginning}
              >
                <Icon type='arrow_back' size={24} />
              </Button>
              <Button
                aria-label='to next slide button'
                onClick={slideNext}
                className='bg-grayscale-000 text-grayscale-800 disabled:text-grayscale-300 [&:not(:disabled)]:hover:bg-grayscale-100 flex h-7 w-7 items-center justify-center rounded-full disabled:cursor-not-allowed'
                disabled={isEnd}
              >
                <Icon type='arrow_forward' size={24} />
              </Button>
            </div>
          )}
        </div>
        {description ? (
          isHTML(description) ? (
            <span
              dangerouslySetInnerHTML={{ __html: description }}
              className='break-words [&_*]:m-0'
            />
          ) : (
            <Text variant='body_m_400' className='break-words'>
              {description}
            </Text>
          )
        ) : showSkeleton ? (
          <div className='bg-grayscale-200 h-8 w-3/4 animate-pulse' />
        ) : null}
      </div>
      <HorizontalSwiperWithSideArrows
        ref={swiperRef}
        showArrows={false}
        absoluteArrows={false}
        slideClassName={twMerge('w-full flex overflow-hidden', slideClassName)}
        className={twMerge('w-full', className)}
        modules={pagination ? [Pagination, ...(modules ?? [])] : modules}
        pagination={pagination ? { clickable: true } : false}
        watchSlidesProgress
        onToEdge={onToEdge}
        onFromEdge={onFromEdge}
        onAfterInit={onAfterInit}
        {...restProps}
      />
    </div>
  )
}

export default SwiperWithTitleAndControls
