import { twMerge } from 'tailwind-merge'

const BORDER_BOTTOM =
  'mb-5 border-0 border-b border-solid border-grayscale-100 pb-5 last:border-0' as const
const RecommendedCardSkeleton = ({ length = 1 }: { length?: number } = {}) => {
  return (
    <>
      {Array.from({ length }, (_, index) => (
        <div
          key={index}
          className={twMerge(BORDER_BOTTOM, 'relative flex flex-col gap-2', 'mobileUp:flex-row')}
        >
          <div className='mobileUp:w-[calc(100%-241px-8px)]'>
            <div className='mobileUp:mb-4 flex gap-4'>
              <div className='bg-grayscale-100 w-[calc(100%-70%-16px)] animate-pulse rounded-lg' />
              <div className='flex w-[70%] flex-col gap-2'>
                <div className='flex items-center gap-2'>
                  <div className='bg-grayscale-100 h-[24px] w-[66px] animate-pulse rounded-full' />
                  <div className='bg-grayscale-100 h-[24px] w-[66px] animate-pulse rounded-full' />
                  <div className='bg-grayscale-100 h-[24px] w-[66px] animate-pulse rounded-full' />
                </div>
                <div className='bg-grayscale-100 h-[52px] animate-pulse' />
              </div>
            </div>
          </div>
          <div className='mobileUp:block mobileUp:w-[241px] hidden'>
            <div
              className={twMerge(
                'mobileUp:border-0 mobileUp:p-0',
                'border-grayscale-100 mb-4 flex flex-col gap-2 border border-solid p-3',
              )}
            >
              <div className='bg-grayscale-100 h-6 animate-pulse' />
              <div className='bg-grayscale-100 h-6 animate-pulse' />
            </div>
            <div className='bg-grayscale-100 h-6 animate-pulse' />
            <div className='bg-grayscale-100 mobileUp:block mt-5 hidden h-12 animate-pulse' />
          </div>
        </div>
      ))}
    </>
  )
}

export default RecommendedCardSkeleton
